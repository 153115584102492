import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Lightlogo from "../Assets/Logo-light.png";
import useState from "react-usestateref";
import Darklogo from "../Assets/Logo.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

function Home() {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const nav_page = () => {
    navigate("/register");
  };
  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };
  return (
    <main className="dashboard_page">
      <section>
        <header className="scrolled">
          <div className="">
            <div className="header_new_mega">
              <div className={`${classes.root} `}>
                <AppBar position="static">
                  <Toolbar className="container">
                    <Typography variant="h6" className={classes.title}>
                      <div className="logo_new_sectio d-flex">
                        <a to="/" className="logo-brand">
                          <img
                            src={Lightlogo}
                            className="img-fluid "
                            alt="logo"
                          />
                        </a>
                        <div className={`menu_new_typr ${classes.appBarItems}`}>
                          <a
                            href="#home"
                            color="inherit"
                            className="contact_button"
                          >
                            Home
                          </a>
                          <a
                            href="#feature"
                            color="inherit"
                            className="contact_button"
                          >
                            Features
                          </a>{" "}
                          <a
                            href="#overview"
                            color="inherit"
                            className="contact_button"
                          >
                            Overview
                          </a>{" "}
                          <a
                            href="#tutorial"
                            color="inherit"
                            className="contact_button"
                          >
                            Tutorial
                          </a>
                        </div>
                      </div>
                    </Typography>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                    >
                      <MenuIcon onClick={handleMobileMenuOpen} />
                    </IconButton>
                    {/* <div className={`menu_new_typr ${classes.appBarItems}`}>
                      <Button className="nav-btn" onClick={nav_page}>
                        Register
                      </Button>
                    </div> */}
                  </Toolbar>
                </AppBar>
                <Drawer
                  anchor="left"
                  open={mobileMenuOpen}
                  className={classes.drawer}
                  onClose={() => handleMobileMenuClose(false)}
                >
                  <div className={classes.mobileMenu}>
                    <div className="logo_new_sectio mobile_menu_icon">
                      <Link to="#" className="logo-brand">
                        <img
                          src={Darklogo}
                          className="img-fluid m-3"
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <div className="menu_statis">
                      <ListItem button className="drawa">
                        <a href="#home">Home</a>
                      </ListItem>
                      <ListItem button className="drawa">
                        <a href="#feature">Features</a>
                      </ListItem>
                      <ListItem button className="drawa ">
                        <a href="#overview">Overview</a>
                      </ListItem>

                      <ListItem button className="drawa ">
                        <a href="#tutorial">Tutorial</a>
                      </ListItem>

                      {/* <ListItem button className="drawa ">
                        <Button onClick={() => navigate("/register")}>
                          Register
                        </Button>
                      </ListItem> */}
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </header>
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>PRIVACY POLICY</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <p>
                      This Privacy Policy (“Policy”) applies to Rifa World Trade
                      (the “Company,” “we,” “us,” or “our”). It outlines the
                      types of personal information we gather when you use Rifa
                      World Trade’s services and the steps we take to safeguard
                      it.
                    </p>
                    <p>
                      By registering for and using the Platform, you agree to
                      the handling of your personal information as described in
                      this Policy and the User Agreement. If you do not wish for
                      your personal data to be used as outlined in this Privacy
                      Policy, you may not use the Rifa World Trade app,
                      services, technologies, products, or features offered by
                      the Rifa World Trade Platform (“Platform”).
                    </p>
                    <p>
                      This Privacy Policy applies to all Personal data
                      processing activities carried out by us, across platforms,
                      websites, and social media of Rifa World Trade.
                    </p>
                    <h3>1.Definitions </h3>
                    <h4>Personal Data</h4>

                    <p>
                      Means any information that relates to an identified or
                      identifiable individual.This includes information you
                      provide to us, and information we obtain from third
                      parties,such as name, phone number, location data, email
                      address, or to one or more factors specific to the
                      physical, economic, cultural or social identity of you as
                      a natural person.
                    </p>
                    <h4>Virtual Financial Asset (VFA)</h4>
                    <p>
                      Means a digital financial instrument, also known as a
                      "convertible virtual currency," "cryptocurrency," "digital
                      currency," "digital commodity," or "digital payment
                      token." Like Bitcoin or Ether, based on cryptographic
                      protocols. It can be (i)centralized or decentralized,
                      (ii)closed or open-source, and (iii) used as a medium of
                      exchange and/or store of value.
                    </p>

                    <h3>2.What Personal Data We collect and process?</h3>
                    <p>
                      Rifa World Trade collects, processes, and stores Personal
                      Data obtained from you through your use of the Service or
                      with your consent. This Personal Data may include contact
                      details, as well as information related to your device or
                      internet service (such as IP address and MAC number).
                    </p>

                    <p>
                      We collect information you provide during your use of the
                      Rifa World Trade Platform, whether the process is
                      completed, incomplete, or abandoned. We also collect
                      Personal Data when you communicate with us, subscribe to
                      marketing communications, correspond with us by phone,
                      email, or other channels, or conduct transactions on the
                      Rifa World Trade Platform. We may actively or
                      automatically collect, use, store, or transfer your
                      Personal Data, which may include, without limitation, the
                      following:
                    </p>

                    <p className="mt-5">(a) The e-mail addresses;</p>
                    <p className="mt-5">
                      (b) Aggregate information concerning what pages users
                      access or visit;
                    </p>
                    <p className="mt-5">
                      (c) Information given by the user (such as survey
                      information and/or site registrations);{" "}
                    </p>
                    <p className="mt-5">
                      (d) Information related to your use of the Website and/or
                      the mobile application, including IP address, Device ID,
                      geographic location, and date and time of your request;{" "}
                    </p>

                    <p className="mt-5">
                      (e) Any other information that you might provide
                      voluntarily. We might also collect your Wallet ID if you
                      decide to participate in one of our campaigns.
                    </p>

                    <p>
                      We collect certain types of information automatically
                      whenever you interact with us or use our services, to the
                      extent permitted under applicable law. This information
                      helps us address customer support issues, enhance the
                      performance of our sites and services, improve your user
                      experience, and safeguard your account by detecting
                      unauthorized access.
                    </p>

                    <h3>3.Cookie</h3>
                    <h4>
                      We use cookies and similar tools to enhance your user
                      experience, provide our services.
                    </h4>

                    <p>
                      3.1 Cookies are alphanumeric identifiers. While you access
                      Rifa World Trade,we may use the industry practice of
                      placing a small amount of data that will be saved by your
                      browser (“Cookies”).
                    </p>
                    <p>
                      3.2 This information helps us recognize you as a customer
                      and collect information about your use of Rifa World Trade
                      to better customize our services and better your
                      experience. We may also use the information collected to
                      ensure compliance with our rules, and to ensure your
                      account security has not been compromised by detecting
                      irregular, suspicious, or potentially fraudulent account
                      activities.{" "}
                    </p>
                    <p>
                      3.3 Most browsers are setup to accept cookies
                      automatically. You have the option to decline the use of
                      our Cookies, but this may affect the functionality of the
                      Services or your user experience.
                    </p>
                    <h3>4.How we use your personal Data</h3>

                    <p>
                      Our primary purpose in collecting personal data is to
                      provide our services in a secure, efficient, and seamless
                      manner. We generally use your personal data to deliver,
                      operate, and enhance our services, as well as for content
                      and advertising, loss prevention, and anti-fraud purposes.{" "}
                    </p>
                    <p>
                      We may also use this data to communicate with you
                      regarding other products or services offered by Rifa World
                      Trade and/or its partners. We do not share your personal
                      data with third parties (other than partners in connection
                      with their services to Rifa World Trade) except where you
                      have given your consent, as further detailed below.
                    </p>

                    <h4>
                      We may share your Personal Data with third parties:{" "}
                    </h4>
                    <p>
                      (a) if we think that sharing it is necessary to enforce
                      the Terms of Service;
                    </p>
                    <p>
                      (b) to comply with government agencies, including
                      regulators, law enforcement and/or justice departments;
                    </p>
                    <p>
                      (c) to third parties who provide services to Rifa World
                      Trade (such as administration or technical services);
                    </p>

                    <h3>5.Security of Your Personal Information</h3>
                    <p>
                      We have implemented reasonable precautions, consistent
                      with applicable laws and regulations, to protect the
                      Personal Information we collect from loss, misuse,
                      unauthorized access, disclosure, alteration, and
                      destruction. To ensure the security of Personal
                      Information and account access, we employ multiple
                      security measures, including:
                    </p>

                    <p>
                      5.1 Physical Measures. Materials containing your personal
                      information will be stored in a locked place. Your
                      personal information will only be stored in the physical
                      form under special circumstances.
                    </p>
                    <p>
                      5.2 Electronic Measures. Computer data containing your
                      personal information will be stored in the computer
                      systems and storage media that are subject to strict login
                      restrictions.
                    </p>

                    <p>
                      5.3 Management Measures. Only authorized employees are
                      permitted to come into contact with your personal
                      information and such employees must comply with our
                      internal confidentiality rules for personal data.
                    </p>

                    <p>
                      5.4 Technical measures. We may use encryption technology
                      to pass your personal data.
                    </p>
                    <p>
                      5.5 Other measures. Our network servers are protected by a
                      variety of security measures.
                    </p>
                    <p>
                      Please note that despite our efforts, no data security
                      measures can guarantee 100% security. Therefore, all users
                      are responsible for independently taking precautions to
                      protect personal information.
                    </p>
                    <p>
                      If you suspect that your personal information has been
                      compromised, you should immediately contact Rifa World
                      Trade team. We are not responsible for any lost, stolen,
                      or compromised user credentials or for any unauthorized
                      activity on your account using your credentials.
                    </p>
                    <p>
                      You agree that we shall not be liable for any information
                      leakage or losses not caused by our intentional misconduct
                      or gross negligence, including but not limited to hacker
                      attacks, power interruptions, or unavoidable technical
                      failures, to the maximum extent permitted by law.
                    </p>
                    <h3>6.Restrictions for Minors</h3>
                    <p>
                      Rifa World Trade does not knowingly allow anyone under the
                      age of 18 to use our services. If you become aware of
                      anyone under 18 using our services, please notify us so we
                      can take prompt action to prevent their access.
                    </p>

                    <h3>7.Retention of Your Personal Information</h3>
                    <p>
                      We retain your information as long as necessary to provide
                      the requested services, subject to legal obligations.
                      Information associated with your account will be kept
                      until it is no longer needed for service provision or
                      until your account is deleted, whichever comes first.
                    </p>
                    <p>
                      Additionally, we may retain information from deleted
                      accounts to comply with legal requirements, prevent fraud,
                      resolve disputes, troubleshoot issues, assist with
                      investigations, enforce the Terms of Use, and undertake
                      other actions permitted by law. All retained information
                      will be managed according to this Privacy Policy.
                    </p>
                    {/*  */}
                    <h3>8.Changes to the Privacy Policy</h3>
                    <p>
                      Our business changes regularly, and any future changes to
                      our Privacy Policy will be posted on this page. Please
                      check back regularly for updates.
                    </p>
                    <p>
                      As stated above, your continued use of Rifa World Trade
                      and its services signifies your acknowledgment and
                      acceptance of these changes.
                    </p>

                    <h3>9.Languages </h3>
                    <p>
                      This Privacy Policy may be published in different
                      languages. In case of any discrepancy, please refer to the
                      English version.
                    </p>
                    <h3>10.Contact Rifa World Trade about Privacy Policy</h3>
                    <p>
                      If you have any questions about this Privacy Policy or the
                      use of your Personal Data, please contact us by sending an
                      email to the following address: support@rifaworld.io
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-10">
                  <img src={require("../Assets/Logo-light.png")} width="100%" />

                  <p>
                    Your gateway to seamless and smart crypto trading.
                    Experience the power of automated trading bots today.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row justify-content-center">
                <div className="col-md-4 foots">
                  <h5>Quick links</h5>

                  <h6>
                    <a href="#home">Home</a>
                  </h6>
                  <h6>
                    <a href="#feature">Features</a>
                  </h6>
                  <h6>
                    <a href="#overview">Overview</a>
                  </h6>
                  <h6>
                    <a href="#tutorial">Tutorial</a>
                  </h6>
                </div>
                <div className="col-md-4">
                  <h5>Socials</h5>

                  <h6>Telegram</h6>
                  <h6>Twitter </h6>
                  <h6>Facebook</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 hrs">
              <div className="copy">
                Copyright © 2024. Rifa All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
