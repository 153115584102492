import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Lightlogo from "../Assets/Logo-light.png";
import useState from "react-usestateref";
import Darklogo from "../Assets/Logo.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

function Home() {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const nav_page = () => {
    navigate("/register");
  };
  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };
  return (
    <main className="dashboard_page">
      <section>
        <header className="scrolled">
          <div className="">
            <div className="header_new_mega">
              <div className={`${classes.root} `}>
                <AppBar position="static">
                  <Toolbar className="container">
                    <Typography variant="h6" className={classes.title}>
                      <div className="logo_new_sectio d-flex">
                        <a to="/" className="logo-brand">
                          <img
                            src={Lightlogo}
                            className="img-fluid "
                            alt="logo"
                          />
                        </a>
                        <div className={`menu_new_typr ${classes.appBarItems}`}>
                          <a
                            href="/"
                            color="inherit"
                            className="contact_button"
                          >
                            Home
                          </a>


                        </div>
                      </div>
                    </Typography>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                    >
                      <MenuIcon onClick={handleMobileMenuOpen} />
                    </IconButton>
                    {/* <div className={`menu_new_typr ${classes.appBarItems}`}>
                      <Button className="nav-btn" onClick={nav_page}>
                        Register
                      </Button>
                    </div> */}
                  </Toolbar>
                </AppBar>
                <Drawer
                  anchor="left"
                  open={mobileMenuOpen}
                  className={classes.drawer}
                  onClose={() => handleMobileMenuClose(false)}
                >
                  <div className={classes.mobileMenu}>
                    <div className="logo_new_sectio mobile_menu_icon">
                      <Link to="#" className="logo-brand">
                        <img
                          src={Darklogo}
                          className="img-fluid m-3"
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <div className="menu_statis">
                      <ListItem button className="drawa">
                        <a href="/">Home</a>
                      </ListItem>


                      {/* <ListItem button className="drawa ">
                        <Button onClick={() => navigate("/register")}>
                          Register
                        </Button>
                      </ListItem> */}
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </header>
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>Term’s and conditions</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <p>
                      We sincerely welcome you to visit Rifa World Trade
                      quantitative trading platform, hereinafter referred to as
                      this platform, please read this user’s agreement
                      carefully. Your actions on this platform will be
                      considered to accept the following service agreements:
                    </p>
                    <p>
                      Confirmation and acceptance of the terms of service of
                      this platform The services provided by this platform will
                      be strictly implemented in accordance with the published
                      articles of incorporation, terms of service and
                      operational rules. Users must fully agree to all terms of
                      service and complete the registration process in order to
                      become an official user of this platform.{" "}
                    </p>
                    <h3>Service Introduction </h3>
                    <h4>
                      This platform uses its own system to provide network
                      services to users through the Internet. At the same time,
                      the user must:
                    </h4>

                    <p>
                      1.Equip yourself with the equipment needed for Internet
                      access, including personal computers, modems or other
                      necessary Internet devices.
                    </p>

                    <p>
                      2.Pay for the telephone and network expenses related to
                      this service.
                    </p>

                    <h5>
                      Based on the importance of the network services provided
                      by this platform, users should agree to:{" "}
                    </h5>
                    <p>1.Provide detailed and accurate personal information.</p>

                    <p>
                      2.Continuously update the registration materials to meet
                      timely, detailed and accurate requirements.
                    </p>

                    <h5>
                      This site does not disclose the user’s name, address,
                      email address, and pen name, with the following
                      exceptions:{" "}
                    </h5>
                    <p>
                      1.The user authorizes this platform to disclose such
                      information.{" "}
                    </p>

                    <p>
                      2.The corresponding laws and procedures require this
                      platform to provide the user’s personal data. If the
                      information provided by the user contains incorrect
                      information, this platform reserves the right to terminate
                      the user’s eligibility to use the network service.{" "}
                    </p>
                    <p>
                      By accessing, using, or attempting to use Smart Bull
                      Services, you acknowledge that you have read, understood,
                      and accepted these Terms of Use. If you disagree, you
                      should not use Smart Bull or its services.
                    </p>

                    <p className="mt-5">
                      After confirming the identity opening permission, the user
                      can obtain the corresponding product service provided by
                      this platform Once the product is confirmed, other
                      products cannot be replaced.
                    </p>
                    <h4 className="mt-4">
                      {" "}
                      Modification of service terms and service revision{" "}
                    </h4>

                    <p>
                      This platform reserves the right to modify the terms of
                      service as necessary. Once the terms of service of this
                      platform have changed, the content will be prompted on the
                      important page. If you do not agree with the changed
                      content, the user can actively cancel the obtained network
                      service. If the user continues to enjoy the web service,
                      it is deemed to accept changes to the terms of service.
                      this platform reserves the right to modify or discontinue
                      the free service at any time without the need to notify
                      the user. this platform exercises the right to modify or
                      discontinue the free service without being responsible to
                      the user or a third party.
                    </p>

                    <h4>User privacy system </h4>
                    <p>
                      Respecting the privacy of users is a basic policy of this
                      platform. Therefore, as a supplement to the analysis of
                      the second personal registration data above, this platform
                      will not disclose, edit or disclose its registration
                      materials and other non-public content stored on this
                      platform without the authorization of the legal user,
                      unless there is a law. The licensing requirements or this
                      platform believes that the disclosure of these letters is
                      necessary in the following four situations on an honest
                      basis:
                    </p>

                    <p>
                      1.Comply with the relevant laws and regulations and comply
                      with the legal service procedures of this platform.
                    </p>
                    <p>2.Maintain the trademark ownership of this platform. </p>
                    <p>
                      3.Strive to maintain the privacy of users and the public
                      in an emergency.{" "}
                    </p>
                    <p>4.Meet other relevant requirements.</p>

                    <h4>User’s account, password and security </h4>
                    <p>
                      Once the user has successfully registered and becomes a
                      legitimate user of this platform, who will get a password
                      and username. The user will be solely responsible for the
                      security of the username and password. In addition, each
                      user is solely responsible for all activities and events
                      conducted under their username. You can always change your
                      password according to the instructions. If the user finds
                      any illegal use of the user account or a security breach,
                      please notify this platform immediately. This platform
                      will ensure that your registration information, purchase
                      information submitted during the purchase process are
                      transmitted securely and properly. Use the above
                      information only when you are providing services.
                    </p>

                    <h4>Refusal to provide guarantee </h4>
                    <p>
                      The user’s personal use of the network service is at risk.
                      This platform does not make any type of guarantee for
                      this. Whether it is clear or implied, this platform does
                      not guarantee that the service will meet the requirements
                      of the user, nor does it guarantee that the service will
                      not be interrupted, and the timeliness, security and error
                      of the service will be There is no guarantee that it will
                      happen. This platform does not guarantee any information
                      services or transaction processes that are available on
                      the website.
                    </p>

                    <h4>Limited liability </h4>
                    <p>
                      This platform is not responsible for any direct, indirect,
                      incidental, special or consequential damages that may
                      arise from improper use of network services, purchase of
                      goods online or services of the same type, transactions on
                      the Internet, and illegal use of network services. Or the
                      information transmitted by the user has changed. These
                      behaviors may cause damage to the image of this platform
                      so this platform has proposed the possibility of such
                      damage in advance. In addition, in order to operate the
                      website properly, this platform needs to stop and maintain
                      the website regularly or irregularly. Therefore, please
                      understand the normal service interruption caused by the
                      situation. this platform will try to avoid service
                      interruptions or limit the interruption time to the
                      shortest time. If this platform system with the payment
                      function in this platform crashes or cannot be used
                      normally due to force majeure or other uncontrollable
                      reasons, this platform will not be responsible for your
                      failure to complete or lose relevant information, records,
                      etc. But it will do its utmost to assist in dealing with
                      the aftermath and work hard to protect you from financial
                      losses.
                    </p>

                    <h4>Storage and restriction of user information </h4>
                    <p>
                      This platform has the right to determine whether the
                      user’s behavior complies with the requirements of this
                      platform’s service terms. If the user violates the terms
                      of the service, This platform has the right to discontinue
                      the provision of network services.
                    </p>

                    <h4>User Management </h4>
                    <p>
                      The user is solely responsible for the content posted.
                      User’s use of the Service is based on all national, local
                      and international legal standards applicable to this
                      platform. Users must follow:
                    </p>

                    <p>
                      1.The transmission of technical data from within Uganda
                      must comply with relevant Uganda laws and regulations.
                    </p>
                    <p>2.The use of network services is not illegal.</p>
                    <p>3.Do not interfere or confuse network services.</p>
                    <p>
                      4.Comply with all network protocols, regulations,
                      procedures, and practices that use network services.
                    </p>

                    <h5>9 Fiat Partner -</h5>
                    <p>
                      A fiat partner is a company or financial institution that
                      provides a bridge between traditional fiat currencies
                      (e.g., USD or EUR) and cryptocurrencies, enabling users to
                      buy and sell digital currencies using fiat money
                    </p>

                    <p className="mt-4">
                      Users must undertake not to transmit any illegal,
                      harassing, slanderous, insulting, intimidating, harmful,
                      vulgar, obscene and other information. In addition, users
                      are not allowed to transmit any information that incites
                      others to constitute a criminal act; they cannot transmit
                      information that contributes to domestic adverse
                      conditions and national security; they cannot transmit any
                      information that does not comply with local, national, and
                      international laws. It is prohibited to illegally enter
                      other computer systems without permission. If the user’s
                      behavior does not meet the above mentioned terms of
                      service, this platform will make an independent judgment
                      to immediately cancel the user service account. Users are
                      responsible for their own online behavior. If the user
                      distributes and disseminates reactionary, pornographic or
                      other content that violates national laws and regulations
                      on this platform, the system records of this platform may
                      be used as evidence that the user violates the law.{" "}
                    </p>
                    {/*  */}
                    <h4>Guarantee</h4>
                    <p>
                      The user agrees to protect and maintain the interests of
                      all members of this platform and is responsible for paying
                      the lawyer’s fees caused by the user’s use beyond the
                      scope of services, and the damage compensation fees for
                      violation of the terms of service.{" "}
                    </p>

                    <h4>End of service </h4>
                    <p>
                      The user or this platform may interrupt one or more
                      network services at any time according to actual
                      conditions. This platform does not need to be responsible
                      for any individual or third party and interrupt the
                      service at any time. If the user disagrees with the
                      subsequent modification of the terms or is dissatisfied
                      with the service of this platform the following rights can
                      be exercised:
                    </p>
                    <p>1.Stop using the network services of this platform.</p>
                    <p>
                      2.Announce this platform to stop the service to the user.
                    </p>
                    <p>
                      After the user service is terminated, the user’s right to
                      use the network service is immediately suspended. Since
                      then, the user has no rights and this platform is not
                      obligated to transmit any unprocessed or unfinished
                      services to users or third parties.{" "}
                    </p>

                    <h4>Notice</h4>
                    <p>
                      All notices sent to users can be sent via announcements on
                      important pages or by email or regular mail. Modifications
                      to the Terms of Service, changes to the Service, or
                      notices of other important events will be made in this
                      form.
                    </p>
                    <h4>Ownership of web service content </h4>
                    <p>
                      The network service content defined by this platform
                      includes: text, software, sound, pictures, video,
                      graphics, all the contents of the advertisement; the
                      entire content of the email; other information provided by
                      this platform for the user. All of this content is
                      protected by copyright, trademark, label and other
                      property ownership laws. Therefore, users can only use
                      these content under the authorization of this platform and
                      advertisers, and cannot copy, reproduce or create
                      derivative products related to the content. All articles
                      on this platform are copyrighted by the original author
                      and this platform Anyone who needs to reprint the articles
                      on this platform must obtain the authorization of the
                      original author or this platform{" "}
                    </p>
                    <h4>About the products sold on this platform </h4>
                    <p>
                      The products sold on this platform will truly publish
                      their parameters, data, historical achievements, and
                      related real introductions, including their advantages and
                      disadvantages. this platform is only responsible for the
                      authenticity and validity of the information transmitted
                      and published, but for the future of the products. How to
                      perform, without any guarantee or guarantee, customers
                      need to objectively and rationally judge the adaptability
                      of the product for any product purchased online. Any loss
                      due to product signals and strategies will not be
                      responsible for any loss of property generated by the
                      customer. Take full responsibility. All online strategy
                      models are intellectual property products and will not be
                      returned once sold. Any dispute arising from the strategy
                      model should be resolved through negotiation. Anyone who
                      solves the problem unilaterally will be invalid.{" "}
                    </p>
                    <h4>Product risk tips</h4>
                    <p>
                      The investment market is extremely risky. Care must be
                      taken to select products. Any type of quantitative product
                      cannot guarantee its future profitability. All online
                      products will publish their real and effective historical
                      data and performance in detail, but how to perform in the
                      future. It will be subject to many conditions such as
                      market changes, hardware environment and human factors.
                      Any user needs to understand and agree that the
                      quantitative investment products can not guarantee the
                      future profitability of the consensus, and then the
                      purchase model can be selected online. No liability is
                      assumed for any risk of shrinking or losing money due to
                      the quantified trading products.{" "}
                    </p>
                    <h4>Disclaimer</h4>
                    <p>
                      This platform does not assume legal responsibility for the
                      consequences of abnormal information transmission caused
                      by any reason such as the stock exchange, the futures
                      exchange satellite transmission line, the
                      telecommunications department and the network service
                      provider, but is obliged to actively assist the exchange,
                      the telecommunications department, The network service
                      provider has returned to normal. Users should always avoid
                      and eliminate the information leakage, errors, loss,
                      delay, interruption and other factors that may adversely
                      affect this platform due to the above reasons, so as to
                      protect them from economic losses and reputation losses.
                      The above reasons are to file a claim with this platform
                      In addition, due to earthquakes, typhoons, wars, strikes,
                      government actions, plagues, explosive and epidemic
                      infectious diseases or other major outbreaks, fires caused
                      by non-pervasive causes, disruptions in basic
                      telecommunications networks, and other parties cannot
                      foresee and The cause of force majeure that cannot be
                      prevented or avoided by the consequences will cause the
                      related services to be interrupted. this platform will not
                      bear the losses incurred, but the cause of the service
                      interruption should be promptly notified and actively
                      resolved.{" "}
                    </p>
                    <h4>Dispute resolution</h4>
                    <p>
                      The dispute between the user and this platform due to the
                      performance of this agreement shall be settled through
                      friendly negotiation. If the negotiation fails to resolve,
                      either party shall have the right to submit the dispute to
                      the Uganda Arbitration Commission for arbitration
                      according to the arbitration rules of the Association.
                    </p>
                    <h4>Special statement</h4>
                    <p>
                      Any user or website that wants to reprint and disseminate
                      the model strategy information of this platform must
                      indicate the source of the reprint, and the author of the
                      relevant strategy agrees, otherwise it is considered
                      infringement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-10">
                  <img src={require("../Assets/Logo-light.png")} width="100%" />

                  <p>
                    Your gateway to seamless and smart crypto trading.
                    Experience the power of automated trading bots today.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row justify-content-center">
                <div className="col-md-4 foots">
                  <h5>Quick links</h5>

                  <h6>
                    <a href="/">Home</a>
                  </h6>
                  <h6>
                    <a href="#feature">Features</a>
                  </h6>
                  <h6>
                    <a href="#overview">Overview</a>
                  </h6>
                  <h6>
                    <a href="#tutorial">Tutorial</a>
                  </h6>
                </div>
                <div className="col-md-4">
                  <h5>Socials</h5>

                  <h6>Telegram</h6>
                  <h6>Twitter </h6>
                  <h6>Facebook</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 hrs">
              <div className="copy">
                Copyright © 2024. Rifa All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
